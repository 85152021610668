// MatchingPro statuses
export const STATUS_PENDING_ANSWER = 'pending_answer'
export const STATUS_JOB_ACCEPTED = 'job_accepted'
export const STATUS_JOB_DECLINED = 'job_declined'

// Job statuses
export const STATUS_PENDING_REALIZATION = 'pending_realization'
export const STATUS_PENDING_START = 'pending_start'
export const STATUS_JOB_IN_PROGRESS = 'job_in_progress'
export const STATUS_PENDING_PAYMENT = 'pending_payment'
export const STATUS_PENDING_NEW_DATE = 'pending_new_date'
export const STATUS_PENDING_RESCHEDULING = 'pending_rescheduling'
export const STATUS_JOB_CANCELED = 'job_canceled'
export const STATUS_JOB_DONE = 'job_done'
export const STATUS_JOB_PENDING_START = 'pending_start'

// Job SubStatuses
export const STATUS_WAITING_FOR_RECEIPT_VALIDATION =
  'waiting_for_receipt_validation'
export const STATUS_WAITING_FOR_RECEIPT_SIGNATURE =
  'waiting_for_receipt_signature'
export const STATUS_BLOCKED_RECEIPT = 'blocked_receipt'

export const FILTER_DATE_HO = 'minDateHo'
export const FILTER_DATE_PRO = 'minDatePro'
export const STATUS_ORDER_DEFAULT = 'ASC'

export const JOB_PERCEIVED_STATUSES = [
  STATUS_PENDING_ANSWER,
  STATUS_PENDING_REALIZATION,
  STATUS_JOB_IN_PROGRESS,
  STATUS_PENDING_PAYMENT,
  STATUS_JOB_DONE,
  STATUS_JOB_DECLINED,
  STATUS_JOB_CANCELED,
]

export const JOB_DATE_WILL_CHANGE_STATUSES = [
  STATUS_PENDING_RESCHEDULING,
  STATUS_PENDING_NEW_DATE,
]

export const JOB_STATUS_ORDER = {
  [STATUS_PENDING_ANSWER]: FILTER_DATE_HO,
  [STATUS_PENDING_REALIZATION]: FILTER_DATE_PRO,
  [STATUS_JOB_IN_PROGRESS]: FILTER_DATE_PRO,
  [STATUS_PENDING_PAYMENT]: FILTER_DATE_PRO,
  [STATUS_PENDING_START]: FILTER_DATE_PRO,
  [STATUS_JOB_DONE]: FILTER_DATE_PRO,
  [STATUS_JOB_DECLINED]: FILTER_DATE_HO,
  [STATUS_JOB_CANCELED]: FILTER_DATE_HO,
}

export const VIRTUAL_STATUS_IN_PROGRESS = 'in_progress'
export const VIRTUAL_STATUS_HISTORICAL = 'historical'
export const VIRTUAL_STATUS_MISSED = 'missed'
export const VIRTUAL_STATUS_BLOCKED = 'blocked_receipt'

// STATUS_PENDING_CANCELATION as been removed from the STATUSES_FOR_VIRTUAL_STATUS_MISSED
// handleJobsRequest will get the jobs foreach "substatus" defined in the STATUSES_FOR_VIRTUAL_STATUS_MISSED constant.
// Calling /engine/jobs?status=pending_cancelation OR /engine/jobs?status=job_canceled retrieve the same results
// but not in the same order.
// This leads to a bug with the groupBy 'status' in "getJobsByStatus" selector combine with the "Pager" component
// Call STATUS_JOB_DECLINED is enough because it already contains the jobs STATUS_PENDING_CANCELATION
export const STATUSES_FOR_VIRTUAL_STATUS_MISSED = [
  STATUS_JOB_DECLINED,
  STATUS_JOB_CANCELED,
]

export const VIRTUAL_STATUSES = {
  [VIRTUAL_STATUS_IN_PROGRESS]: [
    STATUS_PENDING_REALIZATION,
    STATUS_JOB_IN_PROGRESS,
    STATUS_PENDING_PAYMENT,
    STATUS_PENDING_START,
  ],
  [VIRTUAL_STATUS_HISTORICAL]: [
    STATUS_JOB_DONE,
    ...STATUSES_FOR_VIRTUAL_STATUS_MISSED,
  ],
  [VIRTUAL_STATUS_MISSED]: STATUSES_FOR_VIRTUAL_STATUS_MISSED,
  [STATUS_PENDING_REALIZATION]: [
    STATUS_PENDING_REALIZATION,
    STATUS_PENDING_NEW_DATE,
    STATUS_PENDING_RESCHEDULING,
  ],
  [STATUS_JOB_IN_PROGRESS]: [
    STATUS_PENDING_START,
    STATUS_JOB_IN_PROGRESS,
    VIRTUAL_STATUS_BLOCKED,
  ],
}

export const statusesName = {
  [STATUS_PENDING_ANSWER]: 'engine.job.status.pending_assignment',
  [VIRTUAL_STATUS_IN_PROGRESS]: 'engine.job.status.in_progress',
  [STATUS_PENDING_START]: 'engine.job.status.pending_start',
  [STATUS_PENDING_NEW_DATE]: 'engine.job.status.pending_new_date',
  [STATUS_PENDING_REALIZATION]: 'engine.job.status.pending_realization',
  [STATUS_JOB_IN_PROGRESS]: 'engine.job.status.job_in_progress',
  [STATUS_PENDING_PAYMENT]: 'engine.job.status.pending_payment',
  [VIRTUAL_STATUS_HISTORICAL]: 'job.list.status.historical',
  [STATUS_JOB_DONE]: 'engine.job.status.job_done',
  [STATUS_JOB_DECLINED]: 'engine.job.status.job_declined',
  [STATUS_JOB_CANCELED]: 'engine.job.status.job_canceled',
  [VIRTUAL_STATUS_MISSED]: 'job.details.status.missed',
  [VIRTUAL_STATUS_BLOCKED]: 'job.list.status.job_blocked_receipt',
}

export const statusesNameOnDetails = {
  [STATUS_PENDING_ANSWER]: 'job.details.status.job_to_do',
  [STATUS_PENDING_NEW_DATE]: 'job.card.status.pending_new_date',
  [STATUS_PENDING_REALIZATION]: 'job.card.status.pending_realization',
  [STATUS_JOB_IN_PROGRESS]: 'job.card.status.job_in_progress',
  [STATUS_JOB_PENDING_START]: 'engine.job.status.pending_start',
  [STATUS_PENDING_RESCHEDULING]: 'job.card.status.pending_rescheduling',
  [STATUS_PENDING_PAYMENT]: 'job.card.status.pending_payment',
  [STATUS_PENDING_START]: 'job.card.status.pending_start',
  [STATUS_JOB_DONE]: 'job.card.status.job_done',
  [STATUS_JOB_CANCELED]: 'job.details.status.job_canceled',
  [STATUS_JOB_DECLINED]: 'job.details.status.job_declined',
  [VIRTUAL_STATUS_BLOCKED]: 'job.card.status.job_blocked_receipt',
}

export const emptyListLabels = {
  [STATUS_PENDING_ANSWER]: 'job.list.empty.pending_answer',
  [STATUS_PENDING_REALIZATION]: 'job.list.empty.pending_realization',
  [STATUS_JOB_IN_PROGRESS]: 'job.list.empty.job_in_progress',
  [STATUS_PENDING_PAYMENT]: 'job.list.empty.pending_payment',
  [STATUS_JOB_DONE]: 'job.list.empty.job_done',
  [VIRTUAL_STATUS_HISTORICAL]: 'job.list.empty.other',
  [VIRTUAL_STATUS_MISSED]: 'job.list.empty.missed',
}

export const rangeHoursName = {
  all_the_day: 'engine.job.timeslot.range_hours.all_the_day',
  '12h_to_14h': 'engine.job.timeslot.range_hours.12h_to_14h',
}

export const JOB_ACCEPT_TRANSITION = 'JOB_ACCEPT_TRANSITION'
export const JOB_DECLINE_TRANSITION = 'JOB_DECLINE_TRANSITION'

export const POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG =
  'proJobDeclineReasonPopinDisplayed'
export const PRO_JOB_DECLINE_REASON_FORM_NAME = 'proJobDeclineReasonForm'
export const JOBS_PER_PAGE = 6
export const JOBS_PER_PAGE_PENDING_START = 60

export const HTTP_CONFLICT_CODE = 409

export const RECEIPT_SIGNATURE_STATE_FINISHED = 'finished'
export const RECEIPT_SIGNATURE_STATE_MANUAL = 'manual'
export const RECEIPT_SIGNATURE_STATE_BLOCKED = 'blocked'
export const DRAFT_STATUS = 'draft'
export const ACTIVE_STATUS = 'active'
export const EXPIRED_STATUS = 'expired'

export const INVOICE_STATE_NEW = 'new'
export const INVOICE_STATE_AWAITING_VALIDATION = 'awaiting_validation'
export const INVOICE_STATE_AWAITING_PAYMENT = 'awaiting_payment'
export const INVOICE_STATE_VALIDATED = 'validated'
export const INVOICE_STATE_PAID = 'paid'
export const INVOICE_STATE_CONTESTED = 'contested'
export const INVOICE_STATE_MANUAL = 'manual'
export const INVOICE_TRANSITION_CONTEST = 'contest'
export const SIGNATURE_STATE_DONE = 'done'
export const SIGNATURE_STATE_REFUSED = 'refused'

export const CHECK_IN_HO_ERROR_TEXT = 'text'
export const CHECK_IN_HO_ERROR_PRO_EMAIL = 'pro_email'
export const CHECK_IN_HO_ERROR_SHOP_PHONE = 'shop_phone'

export const CHECK_IN_HO_ERRORS = [
  CHECK_IN_HO_ERROR_TEXT,
  CHECK_IN_HO_ERROR_PRO_EMAIL,
  CHECK_IN_HO_ERROR_SHOP_PHONE,
]

export const FIRST_PAGE = 1

// types d'interventions
export const AUTO_AFTER_SALE_TYPE = 'autoAfterSale'
export const MANUAL_INITIAL_AFTER_SALE_TYPE = 'manualAfterSaleInitial'
export const MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE = 'manualAfterSaleDiagnostic'
export const MANUAL_RESOLUTION_AFTER_SALE_TYPE = 'manualAfterSaleResolution'

const AUTO_AND_INITIAL_AFTER_SALE_NAME = '[SAV]'
const MANUAL_DIAGNOSTIC_AFTER_SALE_NAME = '[Diagnostic]'
const MANUAL_RESOLUTION_AFTER_SALE_NAME = '[SAV Résolution]'

export const jobType = type => {
  switch (type) {
    case AUTO_AFTER_SALE_TYPE:
    case MANUAL_INITIAL_AFTER_SALE_TYPE:
      return AUTO_AND_INITIAL_AFTER_SALE_NAME
    case MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE:
      return MANUAL_DIAGNOSTIC_AFTER_SALE_NAME
    case MANUAL_RESOLUTION_AFTER_SALE_TYPE:
      return MANUAL_RESOLUTION_AFTER_SALE_NAME
    default:
      return ''
  }
}
